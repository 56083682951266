import { init, makeFetchTransport, moduleMetadataIntegration, makeMultiplexedTransport } from '@sentry/browser';
import { Envelope, EnvelopeItemType } from '@sentry/types';

interface MatchParam {
  envelope: Envelope;
  getEvent(types?: EnvelopeItemType[]): Event | undefined;
}

const EXTRA_KEY = 'ROUTE_TO';

function dsnFromFeature({ getEvent }: MatchParam) {
  const event = getEvent();
  // todo: need to revisit.
  /* switch (event?.tags?.feature) {
    case 'filing':
      return [{ dsn: '__Filing_DSN__', release: 'filing@1.0.0' }];
    case 'registrations':
      return [{ dsn: '__Registration_DSN__', release: 'registration@1.2.0' }];
    default:
      return [];
  } */
}

init({
  dsn: 'https://6b987acd9049be06f34e724bb5f5829e@o4507549040181248.ingest.us.sentry.io/4507550489182208',
  normalizeDepth: 50,
  integrations: [moduleMetadataIntegration()],
  autoSessionTracking: true,
  replaysSessionSampleRate: 1.0,
  transport: makeMultiplexedTransport(makeFetchTransport, (args) => {
    const event = args.getEvent(['session']);
    if (event && event.extra && EXTRA_KEY in event.extra && Array.isArray(event.extra[EXTRA_KEY])) {
      return event.extra[EXTRA_KEY];
    }
    return [];
  }),
  beforeSend: (event) => {
    event.platform = 'Container';
    event.environment = 'dev';
    event.debug_meta = 'debug meta';
    event.logger = 'logger';
    return event;
  },
});
